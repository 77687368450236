import * as React from "react"
import Container from '../components/Container'
import IndentedContent from '../components/helpers/IndentedContent'
import ContentPage from '../components/layout/ContentPage'
import SectionEntry from '../components/SectionEntry'
import SectionEntryContent from '../components/SectionEntryContent'
import SectionEntryTitle from '../components/SectionEntryTitle'
import Card from '../components/Card'
import { Steps } from 'antd';
import SEO from '../components/seo'

const { Step } = Steps;

// markup
const MigrationPage = () => {
  return (
    <main>
      <SEO title="Migration" />
{/*
      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>
*/}
      <ContentPage>
        <Container>
          <IndentedContent>
            <SectionEntry>
              <SectionEntryTitle>Migration</SectionEntryTitle>

              <SectionEntryContent>
                Du kannst sämtliche Bestandsdaten deines Vereins in petoffice übernehmen.<br />
                Auf Wunsch helfen wir dir auch unkompliziert beim Umzug. Wechsle schnell und einfach zu petoffice.
              </SectionEntryContent>
            </SectionEntry>
          </IndentedContent>
        </Container>

        <Container containerStyles={{ backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
          <IndentedContent style={{position: 'absolute'}}>
            <Card title="Datenimport">
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mi nisl, finibus sed tempus id, mattis ac ex. Quisque turpis felis, sollicitudin eu malesuada et, sollicitudin sed ex. Pellentesque sit amet lacus egestas, porttitor justo ut, volutpat odio. Cras ut erat ex. Duis sed lectus nunc. Nulla pellentesque mi eu turpis ultrices, eu elementum arcu blandit. Cras viverra, ligula in finibus congue, neque massa scelerisque erat, cursus placerat ipsum augue eget lectus. Suspendisse iaculis diam vitae scelerisque venenatis.
              </p>
              <p>
              Pellentesque congue pulvinar lectus, eu consectetur magna vestibulum non. Aenean sed justo nec elit gravida suscipit. Nulla facilisi. Quisque viverra velit diam, sed egestas lectus aliquam eget. Praesent nec aliquet felis, id feugiat eros. Nulla ultrices nec turpis tincidunt placerat. Morbi rutrum, nibh vitae porta tempus, erat ligula tincidunt dolor, eu aliquam augue lacus nec metus. Donec in ex elit. Cras est orci, ornare id ante a, bibendum porta diam.
              </p>
            </Card>
          </IndentedContent>
        </Container>

        <Container>
          <IndentedContent>
            <h3>Zusatzfelder</h3>
          </IndentedContent>
        </Container>

        <Container>
          <IndentedContent>
            <h3>Installationsservice</h3>
          </IndentedContent>
        </Container>

        <Container>
          <IndentedContent>
            <h3>Eigene Ansichten</h3>
          </IndentedContent>
        </Container>
      </ContentPage>
    </main>
  )
}

export default MigrationPage
