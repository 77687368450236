import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'

const Card = (props) => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',

    ...props?.containerStyles || {},
  };

  const subContainerStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',

    ...props?.containerStyles || {},
  };

  const textStyles = {
    width: '75%',
    color: 'rgba(29,49,80,.5)',
    fontSize: '0.875rem',
  };

  return (
    <div style={containerStyles}>
      <h3>{props.title}</h3>

      <div style={subContainerStyles}>
        <div style={textStyles}>
          {props.children}
        </div>

        <div>
          <StaticImage src="../images/import.png" alt="Datenimport für petoffice" placeholder="tracedSVG" />
        </div>
      </div>
    </div>
  )
}

export default Card;